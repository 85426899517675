/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px;
}

.contentWithin {
	max-width:650px;
	/*border: 10px solid green;*/
	border: 0px;
}

/* for the accordeon right align */
.right-aligned-header > .mat-content {
	justify-content: space-between;
}
    
.mat-content > mat-panel-title, .mat-content > mat-panel-description {
      flex: 0 0 auto;
}

.mat-icon {
	width: 16px !important;
	height: 16px i !important;
}

.button-text {
	margin-left: 5px;
}
